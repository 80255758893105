import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, throwError, of, Subject } from 'rxjs';
import { Profile, ProfileResponse, StringResponse, FavouritesResponse, ProfileListResponse, Favourite } from '../classes/profile';
import { LocalStorage, UtilsService, LocalStorageWrapper } from './utils.service';

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { switchMap, mergeMap, tap } from 'rxjs/operators';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
// import { HttpResponse } from '@angular/common/http';



@Injectable()
export class ProfileService {
    private postCodeApiUrl = environment.postCodeApiUrl;
    public templatDetails:any = {}
    public reRendersliderContent:Subject<any> = new Subject();
    public loader:Subject<any> = new Subject();

    constructor(
        private http: HttpService,
        private auth: AuthService,
        private localStorage: LocalStorage,
        private storageService: LocalStorageWrapper,
        private utilService: UtilsService,
    ) { }

    getProfileDetails(force: boolean): Observable<ProfileResponse> {
        if (!this.auth.isLoggedIn()) {
            return throwError({ error: { message: 'You must be logged in' } });
        }

        let url = 'api/customer';
        return this.http.getCustomerJsonBaseUrl(url);
    }
    getConfigData(): Observable<any> {
        return this.http.getHttpJson('/v1/api/profiles/getconfigdata');
    }
    saveProfile(profile: Profile): Observable<any> {
        if (!this.auth.isLoggedIn()) {
            return throwError({ error: { message: 'You must be logged in' } });
        }
        return this.http.putCustomerJsonBaseUrl('api/customer', profile);
    }

    resolvePostcode(postcode: String): Observable<any> {
        const url = this.postCodeApiUrl + postcode.replace(/\s/g, '');
        return this.http.getAbsHttpJson(url);
    }

    updateProfilePicture(picture: string): Observable<StringResponse> {
        if (!this.auth.isLoggedIn()) {
            return throwError({ error: { message: 'You must be logged in' } });
        }
        return this.http.postHttpJson('/v1/api/profiles/postprofilepicture', { files: [picture] });
    }
    removeProfilePicture(): Observable<any> {
        if (!this.auth.isLoggedIn()) {
            return throwError({ error: { message: 'You must be logged in' } });
        }
        return this.http.getHttpJson('/v1/api/profiles/getremoveprofilepicture', true);
    }
    deleteAccount(): Observable<any> {
        if (!this.auth.isLoggedIn()) {
            return throwError({ error: { message: 'You must be logged in' } });
        }
        return this.http.getHttpJson('/v1/api/profiles/getdeleteprofile', true);
    }
    signOut() {
        this.auth.signOut();
        this.localStorage.removeItem('profiles/self');
        this.storageService.setCart([]);
    }
    backendProfileList(list: string[]): Observable<ProfileListResponse> {

        const outputMap = this.getCachedProfile(list);
        list = list.filter(function (x) { return !outputMap || !outputMap.hasOwnProperty(x); });
        if (list.length < 1) {
            return of({
                message: '',
                responseStatus: 1,
                data: Object.keys(outputMap).map(function (key, index) {
                    return outputMap[key];
                }),
            });
        }

        return this.http.postHttpJson('/v1/api/profiles/postgetbackendprofiles', { list: list })
            .pipe(switchMap((result: ProfileListResponse) => {

                this.saveCachedProfile(result.data);

                const outputData = result.data.concat(
                    outputMap ? Object.keys(outputMap).map(function (key, index) {
                        return outputMap[key];
                    }) : []
                );

                return of({
                    message: result.message,
                    responseStatus: result.responseStatus,
                    data: outputData,
                });
            }));
    }
    changePassword(oldPassword: string, newPassword: string): Observable<any> {
        if (!this.auth.isLoggedIn()) {
            return throwError({ error: { message: 'You must be logged in' } });
        }

        return this.auth.getAccessToken().pipe(switchMap(token => {
            return this.http.postHttpJson('/v1/api/profiles/postchangepassword', {
                access_token: token,
                password: oldPassword,
                newpassword: newPassword
            });

        }));
    }


    addFavourites(type: string, itemId: string): Observable<StringResponse> {

        if (!this.auth.isLoggedIn()) {
            return throwError({ error: { message: 'You must be logged in' } });
        }
        this.clearCachedFavourites(type);

        const url = `/v1/api/bookmarks/getadditemtobookmark?itemID=${itemId}&itemType=${type}`;
        return this.http.getHttpJson(url, true);
    }
    getFavourites(type: string): Observable<FavouritesResponse> {

        return this.utilService.checkSophomore('getFavourites').pipe(mergeMap(result => {
            const cachedFavourites = this.getCachedFavourites(type);
            if (cachedFavourites) {
                return of({
                    responseStatus: 1,
                    message: '',
                    data: cachedFavourites
                });
            }

            if (!this.auth.isLoggedIn()) {
                return throwError({ error: { message: 'You must be logged in' } });
            }

            this.utilService.markSophomore('getFavourites');
            return this.http.postHttpJson('/v1/api/bookmarks/postbookmarkdetails', {
                itemType: type
            }).pipe(tap(bookmarkResponse => {
                this.utilService.clearSophomore('getFavourites');
            }));
        }));



    }
    removeFavourite(type: string, itemId: string): Observable<StringResponse> {
        if (!this.auth.isLoggedIn()) {
            return throwError({ error: { message: 'You must be logged in' } });
        }
        this.clearCachedFavourites(type);
        return this.http.postHttpJson(`/v1/api/bookmarks/postremoveitemfrombookmark?itemType=${type}`, {
            itemID: [itemId]
        });
    }

    getUid(): string {
        return this.auth.getLastUser();
    }
    isLoggedIn(): boolean {
        return this.auth.isLoggedIn();
    }
    gotoLogin() {
        return this.auth.gotoLogin();
    }
    getCachedFavourites(type: string): Favourite[] {
        const favourites = this.localStorage.getItem(`favourites/${type}`);
        if (favourites && favourites !== '') {
            try {
                return JSON.parse(favourites);
            } catch (e) {
                return null;
            }
        } else { return null; }
    }
    saveCachedFavourites(type: string, obj: Favourite[]) {
        this.localStorage.setItem(`favourites/${type}`, JSON.stringify(obj));
    }
    clearCachedFavourites(type: string) {
        this.localStorage.setItem(`favourites/${type}`, '');
    }
    getCachedOwnProfile(): Observable<ProfileResponse> {
        const profileStr = this.localStorage.getItem(`profiles/self`);
        if (profileStr) {
            try {
                return of(JSON.parse(profileStr));
            } catch (e) {
            }
        }
        return this.getProfileDetails(true).pipe(tap(result => {
            this.localStorage.setItem('profiles/self', JSON.stringify(result));
        }));
    }

    getCachedProfile(idList: string[]): Map<string, Profile> {
        const profileList = this.localStorage.getItem(`profiles/list`);
        const outputMap = new Map;
        if (profileList && profileList !== '') {
            try {
                const profileJson = JSON.parse(profileList);
                idList.forEach(id => {
                    if (profileJson.hasOwnProperty(id)) {
                        outputMap[id] = profileJson[id];
                    }
                });
                return outputMap;

            } catch (e) {
                return outputMap;
            }
        } else { return outputMap; }
    }
    saveCachedProfile(objList: Profile[]) {
        const profileList = this.localStorage.getItem(`profiles/list`);
        let profileJson = {};
        if (profileList && profileList !== '') {
            try {
                profileJson = JSON.parse(profileList);
            } catch (e) {

            }
        }
        objList.forEach(profile => {
            profileJson[profile._id] = profile;
        });
        this.localStorage.setItem('profiles/list', JSON.stringify(profileJson));
    }

    fetchTemplateDetails(){
        return this.http.getHttpNewJson('/web/retailer/website-template')
     }
}

